import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Layout, Seo } from '@components/core';
import { climateApi } from '@api/geo';
import { colorPalette } from '@shared/theme';
import { IClimateResponse } from '@shared/interfaces';
import image1 from '../images/klimaatverandering-extreme-buien-nederland.jpg';
import image2 from '../images/klimaatverandering-hitte-nederland.jpg';
import image3 from '../images/klimaatverandering-droogte-nederland.jpg';
import image4 from '../images/klimaatverandering-wateroverlast-zeespiegelstijging.jpg';
import imageMobile1 from '../images/klimaatverandering-extreme-buien-nederland-mobile.jpg';
import imageMobile2 from '../images/klimaatverandering-hitte-nederland-mobile.jpg';
import imageMobile3 from '../images/klimaatverandering-droogte-nederland-mobile.jpg';
import imageMobile4 from '../images/klimaatverandering-wateroverlast-zeespiegelstijging-mobile.jpg';
import {
	Accordion,
	AddressInput,
	CardDirection,
	Carousel,
	Chart,
	EsriForm,
	getChartProps,
	Header,
	ImageCard,
	InfoSection,
	InfoSectionMobile,
	Jumbotron,
	RangeChart,
	SocialBar,
	SocialInfo,
	Spoiler,
	transChartformValues,
	WaterLevel,
} from '@components/ui';
import { navigate } from 'gatsby';

interface IClimatePageProps {
	location: {
		search: string;
	};
}

interface IStyledProps {
	withPadding?: boolean;
	direction?: CardDirection;
}

const SectionWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	${(props: IStyledProps) => {
		return props.withPadding
			? 'padding: 200px 60px 0 60px'
			: 'padding: 20px 60px 0';
	}};

	@media screen and (max-width: 992px) {
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
`;

const SectionsWrapper = styled.div`
	display: flex;
	justify-content: center;
	max-width: 1280px;
	flex-direction: column;
	margin: 0 auto;

	@media screen and (max-width: 992px) {
		margin: 36px auto 0;
		max-width: 480px;
	}
`;

const InputWrapperStyled = styled.div`
	position: sticky;
	display: flex;
	align-items: center;
	top: -30px;
	left: 0;
	padding: 40px 60px 0;
	height: 62px;
	background-color: transparent;
	z-index: 99;

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

const MobileLayoutStyled = styled.div`
	display: none;
	flex-direction: column;

	@media screen and (max-width: 992px) {
		display: flex;
	}
`;

const DesktopLayoutStyled = styled.div`
	display: none;
	flex-direction: column;

	@media screen and (min-width: 992px) {
		display: flex;
	}
`;

const DarkSectionStyled = styled.div`
	background-color: ${colorPalette.black};
`;

const GraySectionStyled = styled.div`
	background-color: ${colorPalette.concrete};

	@media screen and (max-width: 992px) {
		margin-top: -45px;
	}
`;

const ClimatePage: FC<IClimatePageProps> = ({ location }) => {
	const [lockBody, setLockBody] = useState<boolean>(false);
	const [data, setData] = useState<IClimateResponse>({
		OBJECTID: 0,
		postcode: '',
		Tropische_dagen_huidig: '0',
		tropische_dagen_2050: '0',
		neerslagtekort_huidig: '',
		neerslagtekort_2050: '',
		overstromingsdiepte: '0',
		jaarlijkse_neerslag_huidig: '',
		jaarlijkse_neerslag_2050: '',
	});

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const postcode =
			urlParams.get('postcode') || localStorage.getItem('postcode');

		if (!postcode) {
			navigate('');
			return;
		}

		climateApi.getData(postcode).then((response) => {
			if (response.features.length) {
				setData(response.features[0].attributes);
			}
		});
	}, [location.search]);

	const wateroverlastData = getChartProps(1200, 200, [
		data.jaarlijkse_neerslag_huidig,
		data.jaarlijkse_neerslag_2050,
	]);

	const hitteData = transChartformValues([
		data.Tropische_dagen_huidig,
		data.tropische_dagen_2050,
	]);

	const droogteData = getChartProps(360, 60, [
		data.neerslagtekort_huidig,
		data.neerslagtekort_2050,
	]);

	return (
		<Layout isBodyLocked={lockBody}>
			<Seo />
			<SocialBar />
			<Header onToggle={setLockBody} />
			<Jumbotron postcode={data.postcode} />
			<InputWrapperStyled>
				<AddressInput value={data.postcode} />
			</InputWrapperStyled>
			<SectionsWrapper>
				<MobileLayoutStyled>
					<InfoSectionMobile
						title="Wateroverlast"
						subtitle="Het gaat natter worden"
						image={imageMobile1}
						href="https://www.arcgis.com/apps/Media/index.html?appid=d370ef526f444e2d8fa3ccf5bb1abb01"
						cardTitle="Gemiddelde hoeveelheid neerslag per jaar in mm"
						cardContent={
							<Spoiler
								withPadding
								scrollTo="#hitte"
								withScroll
								text="Heel veel natter…de gemiddelde hoeveelheid neerslag per jaar gaat overal in Nederland toenemen, in alle verschillende klimaatscenario’s. In de grafiek kunt u de gemiddelde hoeveelheid neerslag per jaar bekijken voor uw omgeving, nu en in 2050."
								action="Extra uitleg over wateroverlast?"
								altAction="Uitleg verbergen"
							/>
						}
					>
						<Chart {...wateroverlastData} isMobile />
					</InfoSectionMobile>

					<InfoSectionMobile
						id="hitte"
						withPadding
						title="Hitte"
						href="https://www.arcgis.com/apps/Media/index.html?appid=9018f8f6e1e84e2d9c086e8616a30c3f"
						subtitle="De zomers worden extremer, winters worden milder"
						image={imageMobile2}
						cardTitle="Aantal tropische <br /> dagen boven 30 °C"
						cardContent={
							<Spoiler
								scrollTo="#droogte"
								withScroll
								withPadding
								text="Veel tropische dagen klinkt misschien leuk, maar langdurige blootstelling aan hitte is voor niemand goed. Voor veel ouderen en zieken kan dit weer zelfs gevaarlijk zijn. In de toekomst zal het aantal tropische dagen boven de 30 °C in Nederland alleen maar verder toenemen. De grafiek toont het gemiddelde aantal tropische dagen boven 30 °C voor uw omgeving."
								action="Extra uitleg over hitte?"
								altAction="Uitleg verbergen"
							/>
						}
					>
						<RangeChart {...hitteData} />
					</InfoSectionMobile>

					<InfoSectionMobile
						id="droogte"
						withPadding
						title="Droogte"
						href="https://www.arcgis.com/apps/Media/index.html?appid=30ef321d1f824a41b9846de30838a440"
						subtitle="Minder neerslag in de zomer, meer neerslag in de winter"
						image={imageMobile3}
						cardTitle="Gemiddelde neerslagtekort <br /> (mm) per jaar"
						cardContent={
							<Spoiler
								withPadding
								withScroll
								scrollTo="#overstroming"
								text="Voor het meten van droogte wordt het neerslagtekort gebruikt. De grafiek toont het gemiddelde neerslagtekort in het huidige klimaat en voor het klimaat rond 2050. Minder neerslag heeft onder andere invloed op lage grondwaterstanden en bodemdaling met onomkeerbare schade als gevolg."
								action="Extra uitleg over droogte?"
								altAction="Uitleg verbergen"
							/>
						}
					>
						<Chart {...droogteData} isMobile />
					</InfoSectionMobile>

					<InfoSectionMobile
						id="overstroming"
						withPadding
						href="https://www.arcgis.com/apps/Media/index.html?appid=8709d6b980714f4caf06e823babfada9"
						title="Overstroming"
						subtitle="De kans op natte voeten wordt groter"
						image={imageMobile4}
						cardContent={
							<Spoiler
								withScroll
								scrollTo="#veelgesteldevragen"
								text="De overstromingsdiepte bepaalt de mate waarin een gebied wordt blootgesteld aan de effecten van een overstroming. De overstromingsdiepte is van grote invloed op de omvang van de schade en het aantal slachtoffers door een overstroming. De grafiek toont de overstromingsdiepte in uw omgeving en de mogelijke impact daarvan."
								action="Extra uitleg over overstroming?"
								withPadding
								altAction="Uitleg verbergen"
							/>
						}
					>
						<WaterLevel
							level={data.overstromingsdiepte || '0'}
							description="De maximale overstromingsdiepte voor uw locatie is"
						/>
					</InfoSectionMobile>
				</MobileLayoutStyled>

				<DesktopLayoutStyled>
					<SectionWrapper>
						<InfoSection
							href="https://www.arcgis.com/apps/Media/index.html?appid=d370ef526f444e2d8fa3ccf5bb1abb01"
							title="Wateroverlast"
							text="Heel veel natter…de gemiddelde hoeveelheid neerslag per jaar gaat overal in Nederland toenemen, in alle verschillende klimaatscenario’s. In de grafiek kunt u de gemiddelde hoeveelheid neerslag per jaar bekijken voor uw omgeving, nu en in 2050."
						>
							Het gaat natter worden
						</InfoSection>
						<ImageCard
							image={image1}
							cardTitle="Gemiddelde hoeveelheid <br /> neerslag per jaar in mm"
							alt="Hoeveelheid neerslag per jaar neemt overal in Nederland toe als gevolg van klimaatverandering. Wat is het effect in uw omgeving?"
						>
							<Chart {...wateroverlastData} />
						</ImageCard>
					</SectionWrapper>
					<SectionWrapper withPadding>
						<InfoSection
							href="https://www.arcgis.com/apps/Media/index.html?appid=9018f8f6e1e84e2d9c086e8616a30c3f"
							direction="right"
							title="Hitte"
							text="Veel tropische dagen klinkt misschien leuk, maar langdurige blootstelling aan hitte is voor niemand goed. Voor veel ouderen en zieken kan dit weer zelfs gevaarlijk zijn. In de toekomst zal het aantal tropische dagen boven de 30 °C in Nederland alleen maar verder toenemen. De grafiek toont het gemiddelde aantal tropische dagen boven 30 °C voor uw omgeving."
						>
							De zomers worden extremer, winters worden milder.
						</InfoSection>
						<ImageCard
							direction="right"
							size="sm"
							image={image2}
							cardTitle="Aantal tropische dagen boven 30 °C"
							alt="Hittegolf en hittestress: wat betekent klimaatverandering voor uw omgeving?"
						>
							<RangeChart {...hitteData} />
						</ImageCard>
					</SectionWrapper>
					<SectionWrapper withPadding>
						<InfoSection
							href="https://www.arcgis.com/apps/Media/index.html?appid=30ef321d1f824a41b9846de30838a440"
							title="Droogte"
							text="Voor het meten van droogte wordt het neerslagtekort gebruikt. De grafiek toont het gemiddelde neerslagtekort in het huidige klimaat en voor het klimaat rond 2050. Minder neerslag heeft onder andere invloed op lage grondwaterstanden en bodemdaling met onomkeerbare schade als gevolg."
						>
							Minder neerslag in de zomer, meer neerslag in de winter
						</InfoSection>
						<ImageCard
							image={image3}
							cardTitle="Gemiddelde neerslagtekort (mm) per jaar"
							alt="Droogte door klimaatverandering, hoe zit het in mijn omgeving?"
						>
							<Chart {...droogteData} />
						</ImageCard>
					</SectionWrapper>

					<SectionWrapper withPadding>
						<InfoSection
							href="https://www.arcgis.com/apps/Media/index.html?appid=8709d6b980714f4caf06e823babfada9"
							direction="right"
							title="Overstroming"
							text="De overstromingsdiepte bepaalt de mate waarin een gebied wordt blootgesteld aan de effecten van een overstroming. De overstromingsdiepte is van grote invloed op de omvang van de schade en het aantal slachtoffers door een overstroming. De grafiek toont de overstromingsdiepte in uw omgeving en de mogelijke impact daarvan."
						>
							De kans op natte voeten wordt groter
						</InfoSection>
						<ImageCard
							size="sm"
							direction="right"
							image={image4}
							alt="Zorgen wateroverlast en zeespiegelstijging voor een overstromingsrisico in uw omgeving?"
							hasPadding={false}
						>
							<WaterLevel
								level={data.overstromingsdiepte || '0'}
								description="De maximale overstromingsdiepte <br /> voor uw locatie is"
							/>
						</ImageCard>
					</SectionWrapper>
				</DesktopLayoutStyled>

				<SocialInfo />
			</SectionsWrapper>

			<DarkSectionStyled>
				<Accordion />
			</DarkSectionStyled>
			<Carousel />
			<GraySectionStyled>
				<EsriForm />
			</GraySectionStyled>
		</Layout>
	);
};

export default ClimatePage;
